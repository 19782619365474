var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 col-md-12"},[_c('div',{staticClass:"row"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(
        _vm.orderDetail.trackOrderLineItems &&
        _vm.orderDetail.trackOrderLineItems.length
      ),expression:"\n        orderDetail.trackOrderLineItems &&\n        orderDetail.trackOrderLineItems.length\n      "}],staticClass:"col-12 col-md-12 orderSingleLabel"},[_vm._v("\n      "+_vm._s(_vm.orderDetail.orderCompleted ? _vm.setOrderListTitle : 'Items on Order')+"\n    ")]),_c('div',{staticClass:"col-12 col-md-12"},[_c('div',{staticClass:"cart-section"},_vm._l((_vm.orderDetail.trackOrderLineItems),function(item){return _c('div',{key:item.orderLineItemID,staticClass:"cart-order"},[_c('div',{class:[
              'cart-product sd-spacing q-ml-none',
              item.linkedProducts && item.linkedProducts.length > 0
                ? 'substitution-product'
                : item.allowSubstitute
                ? 'substitution-product'
                : '',
              item.isFulfilled && item.qty === 0
                ? 'product-not-available'
                : '',
            ]},[_c('div',{staticClass:"cart-produt-data"},[_c('div',{staticClass:"produt-data flex items-start"},[_c('div',{staticClass:"product-image",class:{
                    blacklistedproduct: item.isBlacklisted,
                  }},[_c('box-image',{attrs:{"link":{
                      name: 'Detail',
                      params: { seoName: item.seoName },
                    },"imageSrc":item.image,"width":_vm.isMobile ? '80px' : '86px'}})],1),_c('div',{staticClass:"co-product-details"},[_c('div',{staticClass:"product--title"},[_c('h3',{staticClass:"ellipsis",class:{
                        blacklistedproduct: item.isBlacklisted,
                      }},[_c('router-link',{attrs:{"to":{
                          name: 'Detail',
                          params: { seoName: item.seoName },
                        }}},[_vm._v("\n                        "+_vm._s(item.productName)+"\n                      ")])],1),_c('div',{staticClass:"cart--labels q-mt-sm"},[(item.isAdded)?_c('span',{staticClass:"label-success q-mr-xs",domProps:{"innerHTML":_vm._s(`Just Added`)}}):(item.isUpdated)?_c('span',{staticClass:"label-primary q-mr-xs",domProps:{"innerHTML":_vm._s(`Updated`)}}):_vm._e(),_c('span',{directives:[{name:"show",rawName:"v-show",value:(item.allowSubstitute),expression:"item.allowSubstitute"}],staticClass:"allowed"},[_vm._v("\n                        Substitution Allowed\n                        "),_c('SvgIcon',{staticClass:"q-ml-sm",attrs:{"icon":"info-outline"}})],1),(item.isBlacklisted)?_c('span',{staticClass:"text-negative text-body2 block q-mt-xs"},[_c('q-icon',{attrs:{"name":"info"}}),_vm._v(" Product No Longer on\n                        Site\n                      ")],1):_vm._e(),(item.frequency > 0)?_c('q-banner',{staticClass:"alert-warning alert-sm text-subtitle2 inline-block",attrs:{"inline-actions":"","dense":"","rounded":""}},[_vm._v("\n                        Recurring in every\n                        "),_c('strong',{domProps:{"textContent":_vm._s(_vm.getSubFrequency(item.frequency))}})]):_vm._e()],1),(item.instruction)?_c('div',{staticClass:"cart--instruction text-body2 word-break-word q-mt-sm"},[_c('strong',{staticClass:"text-grey-6"},[_vm._v("Item instruction:")]),_c('br'),_vm._v("\n                      "+_vm._s(item.instruction || '(not set)')+"\n                    ")]):_vm._e(),_c('div',{staticClass:"cart--quantity q-mt-sm"},[_vm._v(_vm._s(item.qty)+" x\n                      "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$options.filters.currency(item.price))}})])]),_c('div',{staticClass:"price"},[(item.totalPrice != item.finalPrice)?_c('span',{staticClass:"original-price",domProps:{"innerHTML":_vm._s(_vm.$options.filters.currency(item.totalPrice))}}):_vm._e(),_c('span',{class:item.totalPrice != item.finalPrice
                          ? 'discount-price'
                          : '',domProps:{"innerHTML":_vm._s(_vm.$options.filters.currency(item.finalPrice))}})]),(item.isFulfilled && item.qty === 0)?_c('div',{staticClass:"product-not-available-label text-negative text-body2"},[_c('span',[_vm._v("Product Not Available")])]):_vm._e()])])]),(item.linkedProducts && item.linkedProducts.length > 0)?_c('div',{staticClass:"substitution-product--block"},_vm._l((item.linkedProducts),function(linkedProduct,index){return _c('div',{key:`${linkedProduct.orderLineItemID}${index}`,staticClass:"substitution-product produt-data flex items-center",class:[
                  linkedProduct.isFulfilled &&
                  linkedProduct.qty === 0 &&
                  item.qty > 0
                    ? 'product-not-available'
                    : '',
                  {
                    blacklistedproduct: linkedProduct.isBlacklisted,
                  },
                ]},[_c('div',{staticClass:"product-image"},[_c('q-avatar',{attrs:{"square":""}},[_c('box-image',{attrs:{"link":linkedProduct.isSellable
                          ? {
                              name: 'Detail',
                              params: { seoName: linkedProduct.seoName },
                            }
                          : '',"imageSrc":linkedProduct.image,"width":"40px"}})],1),_c('q-avatar',{attrs:{"flat":""}},[_c('SvgIcon',{attrs:{"icon":"linked"}})],1)],1),_c('div',{staticClass:"co-product-details"},[_c('div',{staticClass:"product--title"},[_c('h4',[(linkedProduct.isSellable)?_c('router-link',{attrs:{"to":{
                          name: 'Detail',
                          params: { seoName: linkedProduct.seoName },
                        }},domProps:{"innerHTML":_vm._s(linkedProduct.productName)}}):[_vm._v("\n                        "+_vm._s(linkedProduct.productName)+"\n                      ")],_c('q-tooltip',{attrs:{"max-width":"180px","content-class":"bg-dark"}},[_vm._v("\n                        "+_vm._s(linkedProduct.productName)+"\n                      ")])],2),_c('div',{staticClass:"cart--labels q-mt-sm"},[(linkedProduct.isBlacklisted)?_c('span',{staticClass:"text-negative text-body2 block q-mr-sm"},[_c('q-icon',{attrs:{"name":"info"}}),_vm._v(" Product No Longer on\n                        Site")],1):_vm._e(),(
                          linkedProduct.isFulfilled &&
                          linkedProduct.qty === 0 &&
                          item.qty > 0
                        )?_c('span',{staticClass:"product-not-available-label text-negative text-body2"},[_vm._v("Product Not Available")]):_vm._e()])]),_c('div',{staticClass:"price"},[(
                        linkedProduct.totalPrice != linkedProduct.finalPrice
                      )?_c('span',{staticClass:"original-price",domProps:{"innerHTML":_vm._s(
                        _vm.$options.filters.currency(linkedProduct.totalPrice)
                      )}}):_vm._e(),_c('span',{class:linkedProduct.totalPrice != linkedProduct.finalPrice
                          ? 'discount-price'
                          : '',domProps:{"innerHTML":_vm._s(
                        _vm.$options.filters.currency(
                          linkedProduct.finalPrice || linkedProduct.totalPrice
                        )
                      )}})]),_c('div',{staticClass:"flex product-edit--options"},[_c('div',{staticClass:"product-quantity"},[_c('span',{domProps:{"innerHTML":_vm._s(
                          `${linkedProduct.qty} X ${_vm.$options.filters.currency(
                            linkedProduct.price
                          )}`
                        )}})])])])])}),0):_vm._e()])])}),0)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }